/* ============bottom-form-panel============= */

.container-bottom-form-panel {
    width: 100%;
    height: 100vh;
    background-color: rgba(74, 72, 69, 0.5);
    position: fixed;
    top: 0px;
    z-index: 100;
}

.bottom-form-panel {
    max-width: 475px;
    border: solid 1px #e7e5e6;
    background-color: white;
    -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, .1);
            box-shadow: 0 0 50px rgba(0, 0, 0, .1);
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 25px 35px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.container-bottom-form-panel .form-title {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
}

.container-bottom-form-panel .form-title span {
    font-family: 'NunitoSans-Italic', sans-serif;
    margin-bottom: 15px;
    display: block;
    font-size: 20px;
}

.container-bottom-form-panel form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.container-bottom-form-panel input {
    font-family: 'NunitoSans-Italic', sans-serif;
    font-size: 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 205px;
    margin-bottom: 5px;
    padding: 6px 5px;
    color: #0D3866;
    border: solid 1px #d9d9d9;
}

.container-bottom-form-panel input::-webkit-input-placeholder,
.container-bottom-form-panel input::-moz-placeholder,
.container-bottom-form-panel input:-ms-input-placeholder,
.container-bottom-form-panel input::-ms-input-placeholder, 
.container-bottom-form-panel input::placeholder {
    color: #0D3866;
}

.container-bottom-form-panel .form-group-submit input {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-top: 15px;
    margin-bottom: 40px;
    padding: 7px 30px;
    cursor: pointer;
    text-align: center;
    color: #0D3866;
    border: solid 1px #d9d9d9;
    background: none;
    background: #fff;
    width: auto;
}

.container-bottom-form-panel .form-group-submit input:hover {
    /* opacity: .5; */
}

.container-bottom-form-panel .error {
    color: red;
}

.container-bottom-form-panel .message {
    margin-bottom: 10px;
}

.container-bottom-form-panel .telephone-email {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.container-bottom-form-panel .telephone-email a {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #0D3866;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.container-bottom-form-panel .telephone-email a i {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

.container-bottom-form-panel .telephone-email .footer-phone i {
    background: url(../../../landing/images/icons/phonereceiver.svg) no-repeat;
    background-size: contain;
}

.container-bottom-form-panel .telephone-email .footer-email i {
    background: url(../../../landing/images/icons/email-icon.svg) no-repeat;
    background-size: contain;
}


/* ============END bottom-form-panel============= */

@media (min-width: 768px){
    .modal-dialog {
        margin: 2px auto;
    }
}

[data-tooltip] {
	position:relative;

}
[data-tooltip]:after {
    display:none;
    content:attr(data-tooltip);
    position:absolute;
    top: -60px;
    left: -15px;
    padding: 1px 5px;
    background:#f1f1f1;
    color:#333;
    z-index:9;
    font-size: 0.9em;
    line-height:18px;
    border-radius: 3px;
    width: 170px;
}
.disabled[data-tooltip]:hover:after {
	display:block;
}
