footer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    height: 50px;
    padding-right: 60px;
    padding-left: 60px;
    background-color: #0D3866;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    z-index: 1;
    bottom: 0;
    border-top: 1px solid #cbd4dd;
}
.main-container.guest footer{
    bottom: auto;
}
footer .copyright {
    font-size: 12px;
    color: #fff;
}

footer .copyright a {
    color: #fff;
}

footer .copyright p {
    margin: 0;
}

footer .telephone-email {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}
footer img {
    width: 100%;
}
footer .telephone-email a {
    font-size: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #fff;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    margin-right: 10px;
    margin-left: 10px;
}

footer .telephone-email a i {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 15px;
}

footer .telephone-email .footer-email i,
footer .telephone-email .footer-phone i {
    background-size: contain;
    filter: invert(1);
}

footer .social-networks {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 310px;
    -webkit-box-pack: end;
    -webkit-justify-content: flex-end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}

footer .social-networks a {
    display: block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: 5px;
    background-size: contain;
}

footer .social-networks-facebook {
    position: relative;
    top: -2px;
}

@media screen and (max-width: 1050px){
    footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        height: auto;
    }

    footer .social-networks {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    footer .copyright {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
            -ms-flex-order: 3;
                order: 3;
    }
}

@media screen and (max-width: 450px){
    footer {
        padding-right: 5px;
        padding-left: 5px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    footer .social-networks {
        margin-top: 5px;
        margin-bottom: 5px;
    }
}