html, body, div, span,
iframe, h1, h2, h3, h4,
h5, h6, p, blockquote, pre, a,
abbr, address, cite,
code, del, dfn, em, img, ins, kbd,
q, s, samp, small, strong,
sub, sup, var, b, u, i,
dl, dt, dd, ol, ul, li, fieldset,
form, label, legend, table, caption,
tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details,
embed, figure, figcaption, footer,
header, hgroup, menu, nav, output,
ruby, section, summary, time, mark,
audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; }

*, *:before, *:after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box; }

a {
  list-style-type: none;
  text-decoration: none; }

a:focus {
  outline: none; }

li {
  list-style-type: none; }

button:focus {
  outline: none; }