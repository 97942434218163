
@media screen and (max-width: 1370px){
    .landing-page-content .partnership-block .partnership-content .partnership-content-center>div img {
        width: 135px;
    }
    .persons-block .col{
        width: 33%;
    }
    .person-card-block {
        width: 75%;
    }
}
@media screen and (min-width: 1360px) {
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 52vh;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 52vh;
    }

    .landing-page-content .lp-banner-buttom {
        height: 48vh;
    }

    .landing-page-content .lp-banner-top .banner-top-btn {
        margin-top: 70px;
    }
}
@media screen and (min-width: 1080px) and (max-width: 1400px){
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 79vh;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 79vh;
    }

    .landing-page-content .lp-banner-buttom {
        height: 26vh;
    }
    .landing-page-content .lp-banner-top .banner-top-btn {
        margin-top: 100px;
    }
}
@media screen and (min-width: 1360px) and (max-height: 635px) {
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 76vh;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 76vh;
    }

    .landing-page-content .lp-banner-buttom {
        height: 20vh;
    }

    .landing-page-content .lp-banner-top .banner-top-btn {
        margin-top: 40px;
    }

    .landing-page-content .lp-banner-top .banner-top-text {
        margin-top: 5vh;
    }
}
@media screen and (min-width: 1400px){
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 80vh;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 80vh;
    }

    .landing-page-content .lp-banner-buttom {
        height: 25vh;
    }

    .landing-page-content .lp-banner-top .banner-top-btn {
        margin-top: 110px;
    }
}
@media screen and (min-width: 1500px){
    .landing-page-content .lp-banner-top .banner-top-btn {
        margin-top: 150px;
    }
    .tabcontent .cm-content-text p,
.tabcontent .cm-content-text ul,
.tabcontent .cm-content-text span,
.tabcontent .ag-content-text p,
.tabcontent .ag-content-text ul,
.tabcontent .prm-content-text p,
.tabcontent .prm-content-text ul,
.tabcontent .mrm-content-text p,
.tabcontent .mrm-content-text ul,
.tabcontent .mrm-content-text span {
    font-size: 1.2vw;
}
}
@media screen and (min-width: 1900px){
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 65vh;
        background-position-y: -50px;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 65vh;
    }

    .landing-page-content .lp-banner-buttom {
        height: 35vh;
    }
}
@media screen and (min-width: 2400px){
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 75vh;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 75vh;
    }

    .landing-page-content .lp-banner-buttom {
        height: 25vh;
    }

    .landing-page-content .lp-banner-top .banner-top-text {
        margin-top: 17vh;
    }
}
@media screen and (max-width: 1280px){
    .landing-page-content .insights-videos-content .iv-content-slider .image-item-block {
        -webkit-transform: scale(.6);
            -ms-transform: scale(.6);
                transform: scale(.6);
    }
}
@media screen and (max-width: 1080px){
    .personal-card-info li{
        font-size: 11px;
    }
    .video-item {
        width: 70vw !important;
    }
    .landing-page-content .insights-videos-content .iv-content-slider .image-item-block{
        max-width: none;
    }
    .landing-page-wrapper {
        min-width: 320px;
        overflow: hidden;
    }

    .landing-page-wrapper header a.logo{
        margin-right: 0px;
    }

    .lp-header-nav .mob-menu-icon-wrapper {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        z-index: 15;
    }

    .landing-page-wrapper header nav.lp-header-nav {
        max-width: 250px;
        min-width: 250px;
    }

    .landing-page-wrapper header nav.lp-header-nav .mobile-menu {
        display: none;
        position: absolute;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        right: 0px;
        top: 75px;
        background-color: #0D3866;
        width: 230px;
        -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.72);
                box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.72);
        padding: 10px 0;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        visibility: hidden;
        -webkit-transform: translateX(calc(100% + 20px));
            -ms-transform: translateX(calc(100% + 20px));
                transform: translateX(calc(100% + 20px));
        -webkit-transition: .5s ease-in-out;
        -o-transition: .5s ease-in-out;
        transition: .5s ease-in-out;
        z-index: 100;
    }

    .landing-page-wrapper header nav.lp-header-nav .mobile-menu-active {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        visibility: visible;
        -webkit-transform: translateX(0px);
            -ms-transform: translateX(0px);
                transform: translateX(0px);
    }

    .landing-page-wrapper header nav.lp-header-nav .mobile-menu a {
        padding: 10px 0px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top {
        background-position: center;
    }

    .landing-page-content .lp-banner-top .banner-top-text h1 {
        font-size: 3.5vw;
        max-width: 50vw;
    }

    .landing-page-content .lp-banner-top .banner-top-text .banner-top-text-subtitle,
    .landing-page-content .lp-banner-top .banner-top-text h2 {
        font-size: 3vw;
    }

    .landing-page-content .alpha-generation-content,
    .landing-page-content .credit-monitor-content,
    .landing-page-content .insights-videos-content,
    .landing-page-content .macro-regimes-monitor-content,
    .landing-page-content .predictive-risk-management-content,
    .landing-page-content .solutions-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .landing-page-content .alpha-generation-content .ag-content-text,
    .landing-page-content .credit-monitor-content .cm-content-text,
    .landing-page-content .insights-videos-content,
    .landing-page-content .macro-regimes-monitor-content .mrm-content-text,
    .landing-page-content .predictive-risk-management-content .prm-content-text {
        width: 100%;
        margin-top: 15px;
    }

    .landing-page-content .alpha-generation-content .ag-content-slider,
    .landing-page-content .credit-monitor-content .cm-content-slider,
    .landing-page-content .macro-regimes-monitor-content .mrm-content-slider,
    .landing-page-content .predictive-risk-management-content .prm-content-slider {
        width: 100%;
    }

    .landing-page-content .blog-block .blog-content {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .landing-page-content .blog-block .blog-content .blog-content-center p {
        margin-bottom: 5px;
    }

    .landing-page-content .blog-block .blog-content .blog-content-right {
        margin-top: 10px;
    }

    .landing-page-wrapper header nav.lp-header-nav.mobile-menu-open:after {
        content: "";
        display: block;
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: rgba(74,72,69,.5);
        top: 0px;
        left: 0px;
    }

    #disclaimer,
    #privacy {
        width: 95%;
        margin-top: 10px;
    }

    .container-disclaimer .modal-header {
        padding-left: 5px;
    }

    .landing-page-content .partnership-block .partnership-content .partnership-content-center>div img {
        width: 175px;
    }

    .grecaptcha-badge {
        bottom: 100px !important;
    }
}
@media screen and (max-width: 850px){
    .title-block span {
        font-size: 30px;
    }

    .title-block p {
        font-size: 20px;
    }

    .landing-page-content .alpha-generation-content .ag-content-text,
    .landing-page-content .credit-monitor-content .cm-content-text,
    .landing-page-content .insights-videos-content,
    .landing-page-content .macro-regimes-monitor-content .mrm-content-text,
    .landing-page-content .predictive-risk-management-content .prm-content-text {
        font-size: 20px;
    }

    .landing-page-content .lp-banner-top .banner-top-text h1 {
        font-size: 5vw;
        max-width: 100vw;
    }
    .landing-page-content .lp-banner-top .banner-top-text .banner-top-text-subtitle,
    .landing-page-content .lp-banner-top .banner-top-text h2 {
        font-size: 4.5vw;
    }

    .landing-page-content .have-questions-block .have-questions .have-questions-text {
        margin-right: 0px;
    }

    .landing-page-content .solutions-content .how-it-works {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .landing-page-content .solutions-content .how-it-works .how-it-works-text {
        margin-right: 0px;
        text-align: center;
        margin-bottom: 20px;
    }

    .landing-page-content .blog-block .blog-content .blog-content-center {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
    }

    .landing-page-content .blog-block .blog-content .blog-content-center>div {
        margin-right: 0px;
        margin-bottom: 10px;
    }

    .landing-page-content .insights-videos-block {
        padding-right: 5px;
        padding-left: 5px;
        padding-bottom: 30px;
    }

    .insights-videos-block .slick-next {
        right: 0px;
    }

    .insights-videos-block .slick-prev {
        left: 0px;
    }
    .landing-page-content .partnership-block .partnership-content .partnership-content-center>div img {
        width: 130px;
        margin-right: 15px;
    }
}
@media screen and (max-width: 760px){
    .landing-page-content .lp-banner-top .banner-top-text h1 {
        font-size: 5.5vw;
        text-align: center;
    }

    .landing-page-content .lp-banner-top .banner-top-text .banner-top-text-subtitle,
    .landing-page-content .lp-banner-top .banner-top-text h2 {
        font-size: 4vw;
        text-align: center;
    }

    .landing-page-content .lp-banner-top .banner-top-btn {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
    }

    .landing-page-content .lp-banner-top .banner-top-btn .subscribe-for-alerts-btn,
    .landing-page-content .lp-banner-top .banner-top-btn a {
        width: 240px;
        height: 60px;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
    }
    .persons-block .col{
        width: 50%;
    }
    .landing-page-content .lp-banner-top .banner-top-btn {
        margin-top: 70px;
    }
}

@media screen and (max-width: 750px){
    .landing-page-wrapper footer {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        padding-top: 20px;
        padding-bottom: 20px;
        -webkit-box-sizing: border-box;
                box-sizing: border-box;
        height: auto;
    }

    .landing-page-wrapper footer .social-networks {
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        margin-top: 15px;
        margin-bottom: 15px;
    }

    .landing-page-wrapper footer .copyright {
        -webkit-box-ordinal-group: 4;
        -webkit-order: 3;
            -ms-flex-order: 3;
                order: 3;
    }
}

@media screen and (min-width: 750px) and (max-width: 1024px)  {
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 77vh;
    }
    
    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 77vh;
    }

    .landing-page-content .lp-banner-buttom {
        height: 20vh;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top {
        align-items: center;
    }

    .landing-page-content .lp-banner-top .banner-top-text h1 {
        text-align: center;
    }

    .landing-page-content .lp-banner-top .banner-top-text .banner-top-text-subtitle,
    .landing-page-content .lp-banner-top .banner-top-text h2 {
        text-align: center;
    }

    .landing-page-content .solutions-content .tabcontent {
        width: 100%;
        top: 0px !important;
        margin-bottom: 0;
    }

    .landing-page-content .solutions-content .solutions-card {
        height: 100px;
    }

    .landing-page-content .solutions-content .solutions-card:hover {
        box-shadow: none;
        -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
        box-shadow: 0 0 10px rgb(0 0 0 / 10%);;
    }

    .landing-page-content .solutions-content .solutions-card-group {
        margin-top: 0;
    }

    .landing-page-content .solutions-content .solutions-card .solutions-card-title {
        font-size: 13px;
    }

    .landing-page-content .solutions-content .solutions-card .solutions-card-icon svg {
        width: 150px;
        height: 70px;
    }

    .landing-page-content .solutions-content .solutions-card .solutions-card-text {
        display: none;
    }

    .landing-page-content .solutions-content .how-it-works {
        margin-top: 20px;
    }

    .landing-page-content .partnership-block .partnership-content .partnership-content-center>div img {
        width: 70px !important;
    }
    .landing-page-content .lp-banner-top .banner-top-btn {
        margin-top: 40px;
    }
    .landing-page-content .reviews-conteiner .review{
        max-width: 310px;
    }
    .landing-page-content .solutions-content .solutions-card .solutions-card-text {
        display: none;
    }
    .landing-page-content .solutions-content .solutions-card{
        min-width: none;
    }
}

@media screen and (max-width: 1320px) and (min-width: 1000px){
    .landing-page-content .solutions-content .solutions-card{
        width: calc(35% - 15px);
    }
}

@media screen and (min-width: 950px) and (max-width: 1024px){
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 83vh;
    }
    
    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 83vh;
    }
}

@media screen and (min-width: 750px) and (max-width: 1024px) and (max-height: 600px){
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 88vh;
    }
    
    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 88vh;
    }

    .landing-page-content .lp-banner-buttom {
        height: 13vh;
    }
}

@media screen and (max-width: 700px){
    .landing-page-content .lp-banner-top .banner-top-text h1 {
        font-size: 5vw;
    }

    .landing-page-content .lp-banner-top .banner-top-text .banner-top-text-subtitle,
    .landing-page-content .lp-banner-top .banner-top-text h2 {
        font-size: 4vw;
    }

    .landing-page-content .have-questions-block .have-questions {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
    }

    .title-block span {
        font-size: 28px;
    }

    .title-block p {
        font-size: 16px;
    }

    .landing-page-content .about-us-block .title-block ul li {
        font-size: 16px;
    }

    .landing-page-content .alpha-generation-content .ag-content-text,
    .landing-page-content .credit-monitor-content .cm-content-text,
    .landing-page-content .insights-videos-content,
    .landing-page-content .macro-regimes-monitor-content .mrm-content-text,
    .landing-page-content .predictive-risk-management-content .prm-content-text {
        font-size: 16px;
    }

    .landing-page-content .have-questions-block .have-questions .have-questions-text {
        margin-bottom: 20px;
    }

    .landing-page-content .solutions-content .how-it-works .how-it-works-text p {
        font-size: 18px;
    }
}

@media screen and (max-width: 600px){
    .title-block span {
        font-size: 24px;
    }

    .landing-page-content .solutions-content .how-it-works .how-it-works-text p {
        font-size: 16px;
    }
}

@media screen and (max-height: 450px){
    .landing-page-content .lp-banner-top .banner-top-text {
        margin-top: 7vh;
    }
    .landing-page-content .lp-banner-top .banner-top-text h1 {
        margin-top: 1vw;
        margin-bottom: 1vw;
    }
    .landing-page-content .partnership-block .partnership-content .partnership-content-center>div img {
        width: 104px;
        margin-right: 5px;
    }
}

@media screen and (max-height: 400px){
    .landing-page-content .lp-banner-top .banner-top-text h1 {
        font-size: 4vw;
    }

    .landing-page-content .lp-banner-top .banner-top-text .banner-top-text-subtitle,
    .landing-page-content .lp-banner-top .banner-top-text h2 {
        font-size: 3vw;
    }

    .landing-page-content .lp-banner-top .banner-top-btn .subscribe-for-alerts-btn,
    .landing-page-content .lp-banner-top .banner-top-btn a {
        width: 160px;
        font-size: 14px;
        padding-top: 8px;
        padding-bottom: 8px;
        margin-bottom: 5px;
    }

    /* .landing-page-content .lp-banner-top .banner-top-text h1 {
        margin-top: 0vw;
        margin-bottom: 0vw;
    } */
}

@media screen and (max-width: 400px){
    .landing-page-content .solutions-content .solutions-card .solutions-card-title {
        font-size: 9px !important;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 87vh !important;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 87vh !important;
    }
}

@media screen and (max-width: 400px) and (min-height: 650px){
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 88vh !important;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 88vh !important;
    }
}

@media screen and (max-width: 450px){
    header{
        flex-direction: column;
        padding: 5px;
    }
    .mob-menu-icon-wrapper{
        position: absolute;
        right: 7px;
        top: 25px;
        transform: scale(.75);
    }
    .landing-page-content .about-us-block,
    .landing-page-content .alpha-generation-block, 
    .landing-page-content .blog-block,
    .landing-page-content .credit-monitor-block,
    .landing-page-content .reports-block,
    .landing-page-content .insights-videos-block,
    .landing-page-content .macro-regimes-monitor-block,
    .landing-page-content .predictive-risk-management-block{
        padding-right: 5px;
        padding-left: 5px;
    }

    .landing-page-content .alpha-generation-content .ag-content-slider,
    .landing-page-content .credit-monitor-content .cm-content-slider,
    .landing-page-content .macro-regimes-monitor-content .mrm-content-slider,
    .landing-page-content .predictive-risk-management-content .prm-content-slider {
        width: 80%;
    }

    .landing-page-content .partnership-block .partnership-content .partnership-content-center div {
        display: flex;
        flex-direction: column;
    }

    .landing-page-content .partnership-block .partnership-content .partnership-content-center a {
        margin-bottom: 15px;
    }

    .landing-page-content .blog-block .blog-content .blog-content-center>div img {
        display: none;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 88vh;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 88vh;
    }

    .landing-page-content .lp-banner-buttom {
        height: 13vh;
    }

    .landing-page-content .lp-banner-top .banner-top-text .banner-top-text-subtitle,
    .landing-page-content .lp-banner-top .banner-top-text h2 {
        text-align: center;
        padding-left: 0.5em;
        padding-right: 0.5em;
    }

    .landing-page-content .lp-banner-top .banner-top-btn {
        margin-top: 60px;
    }

    .landing-page-content .solutions-content .how-it-works {
        padding-left: 5px;
        padding-right: 5px;
    }

    .landing-page-content .blog-block .blog-content .blog-content-center span,
    .landing-page-content .blog-block .blog-content .blog-content-center p {
        font-size: 16px;
    }

    .landing-page-content .solutions-content .tabcontent {
        width: 100%;
        top: 0px;
        margin-bottom: 0;
    }

    .solutions-tab-conteiner {
        padding: 0px 10px;
    }

    .landing-page-content .solutions-content .solutions-card {
        /* max-width: 22%; */
        height: 75px;
        min-width: unset;
        width: calc(25% - 8px);
    }

    .landing-page-content .solutions-content .solutions-card:hover {
        box-shadow: none;
        -webkit-box-shadow: 0 0 10px rgb(0 0 0 / 10%);
        box-shadow: 0 0 10px rgb(0 0 0 / 10%);;
    }

    .landing-page-content .solutions-content .solutions-card-group {
        margin-top: 0;
        gap: 10px;
    }

    .landing-page-content .solutions-content .solutions-card .solutions-card-title {
        font-size: 11px;
    }

    .landing-page-content .solutions-content .solutions-card .solutions-card-macro-regimes {
        display: none;
    }

    .landing-page-content .solutions-content .solutions-card .solutions-card-title-mobile {
        display: block;
    }

    .landing-page-content .solutions-content .solutions-card .solutions-card-text {
        display: none;
    }

    .landing-page-content .solutions-content .solutions-card .solutions-card-icon svg {
        display: none;
    }
    .landing-page-content .solutions-content .how-it-works {
        margin-top: 20px;
    }

    .landing-page-content .about-us-block .title-block {
        margin-bottom: 0;
    }

    .landing-page-content .about-us-block .persons-block {
        flex-direction: row;
        justify-content: space-around;
        width: 100%;
    }

    .landing-page-content .about-us-block .about-us-content {
        justify-content: space-around;
        margin-bottom: 80px;
    }

    

    .landing-page-content .about-us-block .person-card-block {
        /* width: 160px;
        height: 173px; */
        margin-top: 60px;
    }

    /* .landing-page-content .about-us-block .person-card-block a {
        font-size: 14px;
    }

    .landing-page-content .about-us-block .person-card-block p {
        font-size: 12px;
    }

    .landing-page-content .about-us-block .person-card-block .inner {
        padding: 0.3em;
        padding-left: 1.3em;
    }

    .landing-page-content .about-us-block .person-card-block ul {
        font-size: 10px;
    }

    .landing-page-content .about-us-block .container {
        width: 160px;
    }

    .landing-page-content .about-us-block .front img,
    .landing-page-content .about-us-block .front,
    .landing-page-content .about-us-block .back {
        min-height: 173px;
        max-height: 173px;
    } */

    .bottom-form-panel {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: 100vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding-left: 5px;
        padding-right: 5px;
    }

    .login-form-parent {
        height: 65vh;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -webkit-align-items: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -webkit-justify-content: center;
                -ms-flex-pack: center;
                justify-content: center;
    }

    .login-form {
        padding-left: 0px;
        padding-right: 0px;
    }

    .request-demo-form-wrapper {
        height: 95vh;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
            -ms-flex-pack: center;
                justify-content: center;
        padding-left: 5px;
        padding-right: 5px;
    }

    .persons-block .col{
        width: 100%;
    }
    .personal-card-info {
        display: none;
    }
    .person-card-container:hover .personal-card-info {
        display: block;
    }
}

@media screen and (width: 375px) and (height: 812px){
    .landing-page-wrapper .landing-page-content .lp-banner-top {
        height: 88vh !important;
    }

    .landing-page-wrapper .landing-page-content .lp-banner-top-clone {
        height: 88vh !important;
    }
}

@media screen and (max-width: 360px){
    .landing-page-preloader {
        font-size: 56px;
    }

    .title-block span {
        font-size: 22px;
    }
}

@media screen and (max-width: 360px){
    .landing-page-content .lp-banner-top .banner-top-btn {
        margin-top: 8px;
    }

    .landing-page-content .solutions-content .solutions-card {
        height: 65px;
        padding-top: 15px;
        padding-left: 7px;
    }

    .landing-page-content .solutions-content .solutions-card .solutions-card-title {
        font-size: 8px !important;
    }
}

@media screen and (max-width: 320px) {
    .landing-page-content .about-us-block .person-card-block {
        /* width: 144px;
        height: 156px; */
        margin-top: 60px;
    }

    /* .landing-page-content .about-us-block .container {
        width: 144px;
    }

    .landing-page-content .about-us-block .person-card-block a {
        font-size: 13px;
    }

    .landing-page-content .about-us-block .person-card-block p {
        font-size: 11px;
    }

    .landing-page-content .about-us-block .person-card-block ul {
        font-size: 9px;
    }

    .landing-page-content .about-us-block .front img,
    .landing-page-content .about-us-block .front,
    .landing-page-content .about-us-block .back {
        min-height: 156px;
        max-height: 156px;
    } */
}

@supports not ((-webkit-backdrop-filter: blur(15px)) or (backdrop-filter: blur(15px))) {
    .landing-page-content .insights-videos-block,
    .landing-page-content .solutions-content,
    .landing-page-content .solutions-content .solutions-card,
    .landing-page-content .blog-block,
    .landing-page-content .about-us-block {
        background-color: rgba(0, 48, 84, .9) !important;
    }

    .landing-page-content .solutions-content .solutions-card {
        box-shadow: 0 0 8px rgba(255,255,255,.35);
    }
}

@supports (-webkit-touch-callout: none) {
    .landing-page-content .about-us-block {
        background-color: rgba(0, 48, 84, .9) !important;
    }
}

@media screen and (min-width: 1025px) {
    .landing-page-content .reviews-conteiner .review{
        max-width: 49%;
    }
}