.landing-page-wrapper header {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    /* max-width: 1920px; */
    width: 100%;
    height: 75px;
    padding-right: 3.1%;
    padding-left: 3.1%;
    background-color: #0D3866;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
            box-shadow: 0 3px 5px rgba(0, 0, 0, .1);
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    position: fixed;
    top: 0;
    z-index: 11;
}

.landing-page-wrapper header a.logo {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 28px;
    color: #fff;
    margin-right: 50px;
}

.landing-page-wrapper header nav.lp-header-nav {
    font-family: 'NunitoSans-Regular', sans-serif;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    min-width: 450px;
    max-width: 450px;
    /* max-width: 675px; */
    color: #fff;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.landing-page-wrapper header nav.lp-header-nav a {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    color: #fff;
    border-bottom: solid 1px transparent;
    font-size: 1.1em;
}

.landing-page-wrapper header nav.lp-header-nav a:hover {
    border-bottom: solid 1px #fff;
}


.landing-page-wrapper header nav.lp-header-nav.mobile-menu-open .mobile-menu {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
}
header nav.lp-header-nav .mobile-menu {
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    width: 75%;
    /* width: 50%; */
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.mob-menu-icon-wrapper {
    width: 30px;
    height: 20px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    display: none;
    cursor: pointer;
}

.menu-icon {
    position: relative;
    width: 30px;
    height: 5px;
    background-color: #fff;
}

.menu-icon::before {
    position: absolute;
    left: 0;
    top: -10px;
    content: '';
    width: 30px;
    height: 5px;
    background-color: #fff;
    -webkit-transition: top 0.2s linear 0.2s, -webkit-transform 0.2s ease-in;
    transition: top 0.2s linear 0.2s, -webkit-transform 0.2s ease-in;
    -o-transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
    transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
    transition: transform 0.2s ease-in, top 0.2s linear 0.2s, -webkit-transform 0.2s ease-in;
}

.menu-icon::after {
    position: absolute;
    left: 0;
    top: 10px;
    content: '';
    width: 30px;
    height: 5px;
    background-color: #fff;
    -webkit-transition: top 0.2s linear 0.2s, -webkit-transform 0.2s ease-in;
    transition: top 0.2s linear 0.2s, -webkit-transform 0.2s ease-in;
    -o-transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
    transition: transform 0.2s ease-in, top 0.2s linear 0.2s;
    transition: transform 0.2s ease-in, top 0.2s linear 0.2s, -webkit-transform 0.2s ease-in;
}

.menu-icon.menu-icon-active {
    background-color: transparent;
    z-index: 100;
}

.menu-icon.menu-icon-active::before {
    -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
            transform: rotate(45deg);
    top: 0;
    -webkit-transition: top 0.2s linear, -webkit-transform 0.2s ease-in 0.2s;
    transition: top 0.2s linear, -webkit-transform 0.2s ease-in 0.2s;
    -o-transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
    transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
    transition: top 0.2s linear, transform 0.2s ease-in 0.2s, -webkit-transform 0.2s ease-in 0.2s;
}

.menu-icon.menu-icon-active::after {
    -webkit-transform: rotate(-45deg);
        -ms-transform: rotate(-45deg);
            transform: rotate(-45deg);
    top: 0;
    -webkit-transition: top 0.2s linear, -webkit-transform 0.2s ease-in 0.2s;
    transition: top 0.2s linear, -webkit-transform 0.2s ease-in 0.2s;
    -o-transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
    transition: top 0.2s linear, transform 0.2s ease-in 0.2s;
    transition: top 0.2s linear, transform 0.2s ease-in 0.2s, -webkit-transform 0.2s ease-in 0.2s;
}