/*====== PRELOADER LP ======*/

.landing-page-preloader {
    width: 100vw;
    height: 100vh;
    position: fixed;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    z-index: 5;
    background: #fff;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-transition: all 1s linear;
    -o-transition: all 1s linear;
    transition: all 1s linear;
    z-index: 105;
}

.landing-page-preloader.hidden-preloader {
    opacity: 0;
    visibility: hidden;
}

.landing-page-preloader {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 68px;
    color: #337ab7;
}

.sk-wave {
    margin: auto;
    text-align: center;
}

.sk-wave .sk-rect {
    display: inline-block;
    -webkit-animation: sk-wave-stretch-delay 1.2s infinite ease-in-out;
    animation: sk-wave-stretch-delay 1.2s infinite ease-in-out;
}

.sk-wave .sk-rect-1 {
    -webkit-animation-delay: -1.2s;
    animation-delay: -1.2s;
}

.sk-wave .sk-rect-2 {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-wave .sk-rect-3 {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-wave .sk-rect-4 {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-wave .sk-rect-5 {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-wave .sk-rect-6 {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

@-webkit-keyframes sk-wave-stretch-delay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
                transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }
}

@keyframes sk-wave-stretch-delay {
    0%,
    40%,
    100% {
        -webkit-transform: scaleY(0.4);
                transform: scaleY(0.4);
    }
    20% {
        -webkit-transform: scaleY(1);
                transform: scaleY(1);
    }
}


/*====== END PRELOADER LP ======*/


/*====== PRELOADER SECTION ======*/

.sk-circle-bounce {
    width: 3em;
    height: 3em;
    position: absolute;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    margin: 0 auto;
    top: 110px;
}

.sk-circle-bounce .sk-child {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
}

.sk-circle-bounce .sk-child:before {
    content: '';
    display: block;
    margin: 0 auto;
    width: 15%;
    height: 15%;
    background-color: #337ab7;
    border-radius: 100%;
    -webkit-animation: sk-circle-bounce-delay 1.2s infinite ease-in-out both;
    animation: sk-circle-bounce-delay 1.2s infinite ease-in-out both;
}

.sk-circle-bounce .sk-circle-2 {
    -webkit-transform: rotate(30deg);
        -ms-transform: rotate(30deg);
            transform: rotate(30deg);
}

.sk-circle-bounce .sk-circle-3 {
    -webkit-transform: rotate(60deg);
        -ms-transform: rotate(60deg);
            transform: rotate(60deg);
}

.sk-circle-bounce .sk-circle-4 {
    -webkit-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
            transform: rotate(90deg);
}

.sk-circle-bounce .sk-circle-5 {
    -webkit-transform: rotate(120deg);
        -ms-transform: rotate(120deg);
            transform: rotate(120deg);
}

.sk-circle-bounce .sk-circle-6 {
    -webkit-transform: rotate(150deg);
        -ms-transform: rotate(150deg);
            transform: rotate(150deg);
}

.sk-circle-bounce .sk-circle-7 {
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
}

.sk-circle-bounce .sk-circle-8 {
    -webkit-transform: rotate(210deg);
        -ms-transform: rotate(210deg);
            transform: rotate(210deg);
}

.sk-circle-bounce .sk-circle-9 {
    -webkit-transform: rotate(240deg);
        -ms-transform: rotate(240deg);
            transform: rotate(240deg);
}

.sk-circle-bounce .sk-circle-10 {
    -webkit-transform: rotate(270deg);
        -ms-transform: rotate(270deg);
            transform: rotate(270deg);
}

.sk-circle-bounce .sk-circle-11 {
    -webkit-transform: rotate(300deg);
        -ms-transform: rotate(300deg);
            transform: rotate(300deg);
}

.sk-circle-bounce .sk-circle-12 {
    -webkit-transform: rotate(330deg);
        -ms-transform: rotate(330deg);
            transform: rotate(330deg);
}

.sk-circle-bounce .sk-circle-2:before {
    -webkit-animation-delay: -1.1s;
    animation-delay: -1.1s;
}

.sk-circle-bounce .sk-circle-3:before {
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
}

.sk-circle-bounce .sk-circle-4:before {
    -webkit-animation-delay: -0.9s;
    animation-delay: -0.9s;
}

.sk-circle-bounce .sk-circle-5:before {
    -webkit-animation-delay: -0.8s;
    animation-delay: -0.8s;
}

.sk-circle-bounce .sk-circle-6:before {
    -webkit-animation-delay: -0.7s;
    animation-delay: -0.7s;
}

.sk-circle-bounce .sk-circle-7:before {
    -webkit-animation-delay: -0.6s;
    animation-delay: -0.6s;
}

.sk-circle-bounce .sk-circle-8:before {
    -webkit-animation-delay: -0.5s;
    animation-delay: -0.5s;
}

.sk-circle-bounce .sk-circle-9:before {
    -webkit-animation-delay: -0.4s;
    animation-delay: -0.4s;
}

.sk-circle-bounce .sk-circle-10:before {
    -webkit-animation-delay: -0.3s;
    animation-delay: -0.3s;
}

.sk-circle-bounce .sk-circle-11:before {
    -webkit-animation-delay: -0.2s;
    animation-delay: -0.2s;
}

.sk-circle-bounce .sk-circle-12:before {
    -webkit-animation-delay: -0.1s;
    animation-delay: -0.1s;
}

@-webkit-keyframes sk-circle-bounce-delay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}

@keyframes sk-circle-bounce-delay {
    0%,
    80%,
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    40% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}


/*====== END PRELOADER SECTION ======*/

/*====== SLIDER ======*/

.slick-prev:before {
    position: relative;
    left: -5px;
    display: inline-block;
    width: 30px;
    height: 30px;
    content: '';
    background: url(../../../landing/images/icons/right-arrow.svg) no-repeat;
    -webkit-transform: rotate(180deg);
        -ms-transform: rotate(180deg);
            transform: rotate(180deg);
    bottom: 8px;
}

.slick-next:before {
    display: inline-block;
    width: 30px;
    height: 30px;
    content: '';
    background: url(../../../landing/images/icons/right-arrow.svg) no-repeat;
}

.slick-dots li button:before {
    font-size: 35px;
}

.slick-dots li button:before {
    opacity: 0.35;
    color: white;
}

.slick-dots li.slick-active button:before {
    color: white;
}


/*==== END SLIDER ====*/

body {
    background-color: #f7f7f7;
}

a {
    text-decoration: none;
}

a:hover {
    opacity: 0.8;
    text-decoration: none;
}

.invisible-link{
    color: #000;
}
.invisible-link.white{
    color: #fff;
}

.landing-page-wrapper footer{
    bottom: auto;
}
img {
    width: 100%;
}

.hidden {
    display: none;
}

.landing-page-wrapper {
    width: 100%;
    /* max-width: 1920px; */
    min-width: 1000px;
    margin: 0 auto;
}

.landing-page-wrapper .landing-page-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.blur-conteiner{
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter:blur(15px);
    -moz-backdrop-filter: blur(15px);
    -o-backdrop-filter: blur(15px);
    -ms-backdrop-filter: blur(15px);
    padding-top: 10px;
}

.landing-page-wrapper .landing-page-content .lp-banner-top-clone {
    height: 100vh;
}

.landing-page-wrapper .landing-page-content .lp-banner-top {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: flex-start;
        -ms-flex-pack: flex-start;
            justify-content: flex-start;
    /* max-width: 1920px; */
    width: 100%;
    height: 100vh;
    padding-right: 3.1%;
    padding-left: 3.1%;
    /* background: url(../../../landing/images/Notebook3.jpg) no-repeat; */
    background-color: #003054;
    background-image: url(../../../landing/images/background-main-top1.png);
    background-size: cover;
    background-position-x: center;
    background-position-y: -100px;
    position: fixed;
    top: 75px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.landing-page-content .lp-banner-top .banner-top-text {
    font-family: 'NunitoSans-Regular', sans-serif;
    color: #fff;
    margin-top: 10vh;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    text-align: center;
}

/* .landing-page-content .lp-banner-top .banner-top-text h1 {
    font-size: 2.5vw;
    width: 100%;
    max-width: 44vw;
    margin-top: 5.5vw;
    margin-bottom: 2.5vw;
} */

.landing-page-content .lp-banner-top .banner-top-text h1,
.landing-page-content .lp-banner-top .banner-top-text .banner-top-text-subtitle,
.landing-page-content .lp-banner-top .banner-top-text h2 {
    text-transform: uppercase;
}
.landing-page-content .lp-banner-top .banner-top-text h1 {
    font-size: 2vw;
    width: 100%;
    max-width: 830px;
}
.landing-page-content .lp-banner-top .banner-top-text .banner-top-text-subtitle,
.landing-page-content .lp-banner-top .banner-top-text h2 {
    margin-top: 30px;
}
.landing-page-content .lp-banner-top .banner-top-text .banner-top-text-subtitle{
    font-size: 1.5vw;
}

.landing-page-content .lp-banner-top .banner-top-btn {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    max-width: 900px;
    margin-top: 120px;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}     

.landing-page-content .lp-banner-top .banner-top-btn .subscribe-for-alerts-btn,
.landing-page-content .lp-banner-top .banner-top-btn a {
    display: flex;
    align-items: center;
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 18px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 35px;
    color: #fff;
    border-radius: 5px;
    background: rgba(255,255,255,0.101);
    text-align: center;
    min-width: 200px;
    height: 60px;
    justify-content: center;
    border: 1px solid rgba(255,255,255,0.5);
    width: 32%;
}

.landing-page-content .lp-banner-top .banner-top-btn .subscribe-for-alerts-btn{
    cursor: pointer;
}
.landing-page-content .lp-banner-top .banner-top-btn .subscribe-for-alerts-btn:hover,
.landing-page-content .lp-banner-top .banner-top-btn a:hover {
    box-shadow: 0 0 28px rgba(255,255,255,.35), 0 0 10px rgba(255,255,255,.32);
}

.landing-page-content .lp-banner-buttom {
    background-color: #003054;
    background-image: url(../../../landing/images/background-main-bottom1.png);
    background-size: cover;
    height: 0vh;
    width: 100%;
    /* max-width: 1920px; */
    position: fixed;
    bottom: 0;
}

.landing-page-content .alpha-generation-block,
.landing-page-content .predictive-risk-management-block,
.landing-page-content .credit-monitor-block,
.landing-page-content .reports-block,
.landing-page-content .solutions-block,
.landing-page-content .macro-regimes-monitor-block,
.landing-page-content .insights-videos-block,
.landing-page-content .blog-block,
.landing-page-content .partnership-block,
.landing-page-content .reviews-conteiner,
.landing-page-content .start-trial-block,
.landing-page-content .about-us-block,
.landing-page-content .have-questions-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    padding-right: 7.8vw;
    padding-left: 7.8vw;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    /* background: #ffffff; */
    position: relative;
    z-index: 1;
    padding-bottom: 3.6vw;
}
.landing-page-content .alpha-generation-block,
.landing-page-content .credit-monitor-block,
.landing-page-content .predictive-risk-management-block,
.landing-page-content .macro-regimes-monitor-block {
    padding: 2.8vw;
}
.landing-page-content .insights-videos-block {
    min-height: 200px;
}

.landing-page-content .insights-videos-block .title-block {
    padding-top: 1.5vw;
    padding-bottom: 1vw;
}

.landing-page-content .insights-videos-block .title-block span {
    color: white;
}
.insights-videos-block .title-block {
    padding-bottom: .7vw;
}
.insights-videos-block .title-block .title-block-subtitle,
.insights-videos-block .title-block .title-block-subtitle a {
    color: #fff;
}
.insights-videos-block .title-block .title-block-subtitle a {
    text-decoration: underline;
}
.landing-page-content .have-questions-block {
    display: none;
    padding: 0;
}

/*====== SOLUTIONS TAB ======*/

.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: #f1f1f1;
}

.tab button {
    background-color: inherit;
    float: left;
    border: none;
    outline: none;
    cursor: pointer;
    padding: 14px 16px;
    transition: 0.3s;
    font-size: 17px;
}

.tab button:hover {
    background-color: #ddd;
}

#solutions-section > div > div.solutions-card-group > div.solutions-card.tablinks.active {
    background: rgba(255,255,255,.3);
}
.solutions-tab-conteiner{
    width: 100%;
    padding: 0 30px;
}
.tabcontent {
    display: none;
    width: 100%;
    position: relative;
    top: -190px;
    margin-bottom: -150px;
    z-index: 10;
    border-top: none;
    background: white;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
            box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    -webkit-box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
            box-shadow: 0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22);
}

.tabcontent::after {
    content: '';
    display: block;
    position: absolute;
    top: -10px;
    height: 10px;
    width: 100%;
    background: white;
    z-index: 10;
}

.tabcontent .cm-content-text p,
.tabcontent .cm-content-text ul,
.tabcontent .cm-content-text span,
.tabcontent .ag-content-text p,
.tabcontent .ag-content-text ul,
.tabcontent .prm-content-text p,
.tabcontent .prm-content-text ul,
.tabcontent .mrm-content-text p,
.tabcontent .mrm-content-text ul,
.tabcontent .mrm-content-text span {
    font-size: 18px;
}

/*====== END SOLUTIONS TAB ======*/

.landing-page-content .predictive-risk-management-block {
    background-color: white;
    -webkit-box-shadow: 0 20px 20px rgba(0, 0, 0, .1);
            box-shadow: 0 20px 20px rgba(0, 0, 0, .1);
}

#mc_embed_signup #mc_embed_signup_scroll h2 {
    text-align: center;
}

.title-block {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-top: 3.6vw;
}

.title-block span {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 34px;
    text-align: center;
    color: #0D3866;
    font-weight: bold;
}

.landing-page-content .reports-block {
    display: none;
}
.reports-block .title-block p {
    display: inline-flex;
}
.title-block p {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 22px;
    text-align: center;
    color: #0D3866;
}

.landing-page-content .alpha-generation-content,
.landing-page-content .predictive-risk-management-content,
.landing-page-content .credit-monitor-content,
.landing-page-content .reports-content,
.landing-page-content .insights-videos-content,
.landing-page-content .solutions-content,
.landing-page-content .macro-regimes-monitor-content,
.landing-page-content .insights-videos-content,
.landing-page-content .blog-content,
.landing-page-content .partnership-content,
.landing-page-content .about-us-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.landing-page-content .reports-block h3{
    text-transform: uppercase;
}
.landing-page-content .reports-block a{
    font-weight: bold;
    cursor: pointer;
    color: inherit;
    text-decoration: underline;
}
.landing-page-content .reports-content{
    display: flex;
    justify-content: center;
}

.landing-page-content .alpha-generation-content .ag-content-slider,
.landing-page-content .predictive-risk-management-content .prm-content-slider,
.landing-page-content .credit-monitor-content .cm-content-slider,
.landing-page-content .macro-regimes-monitor-content .mrm-content-slider {
    width: 40%;
}
.landing-page-content .reports-content .r-content-slider{
    width: 55%;
}
.landing-page-content .macro-regimes-monitor-content .mrm-content-slider .image-item-block,
.landing-page-content .alpha-generation-content .ag-content-slider .image-item-block,
.landing-page-content .predictive-risk-management-content .prm-content-slider .image-item-block,
.landing-page-content .credit-monitor-content .cm-content-slider .image-item-block,
.landing-page-content .reports-content .r-content-slider .image-item-block {
    padding: 25px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.landing-page-content .credit-monitor-content .cm-content-slider .image-item-block.cm-content-slide-cm2 {
    -webkit-transform: translateY(20%);
        -ms-transform: translateY(20%);
            transform: translateY(20%);
}

.landing-page-content .macro-regimes-monitor-content .mrm-content-slider .image-item-block img,
.landing-page-content .alpha-generation-content .ag-content-slider .image-item-block img,
.landing-page-content .predictive-risk-management-content .prm-content-slider .image-item-block img,
.landing-page-content .reports-content .r-content-slider .image-item-block img,
.landing-page-content .credit-monitor-content .cm-content-slider .image-item-block img {
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.72);
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.72);
}

.landing-page-content .predictive-risk-management-content .prm-content-slider img {
    width: 100%;
    margin: 0 auto;
}

.landing-page-content .predictive-risk-management-content .prm-content-slider .prm-content-slide-prm2 {
    position: relative;
}

.landing-page-content .alpha-generation-content .ag-content-text,
.landing-page-content .predictive-risk-management-content .prm-content-text,
.landing-page-content .credit-monitor-content .cm-content-text,
.landing-page-content .insights-videos-content,
.landing-page-content .macro-regimes-monitor-content .mrm-content-text {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 22px;
    width: 50%;
}

#alpha-generation-section {
    display: block;
}

.landing-page-content .alpha-generation-block {
    background-color: white;
}

.landing-page-content .alpha-generation-content .ag-content-text p {
    margin-bottom: 10px;
}

#predictive-risk-management-section {
    display: block;
}

.landing-page-content .predictive-risk-management-content .prm-first-paragraph {
    margin-bottom: 20px;
}

.landing-page-content .predictive-risk-management-content .prm-first-paragraph p {
    margin-bottom: 10px;
}

.landing-page-content .predictive-risk-management-content .prm-second-paragraph {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
}

.landing-page-content .predictive-risk-management-content .prm-second-paragraph span {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-weight: bold;
    margin-bottom: 10px;
}

#credit-monitor-section {
    display: block;
}

.landing-page-content .credit-monitor-block {
    background-color: white;
}

.landing-page-content .credit-monitor-content .cm-content-text span {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-weight: bold;
    display: inline-block;
    margin-top: 20px;
    margin-bottom: 10px;
}

.landing-page-content .credit-monitor-content .cm-content-text ul {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding-left: 20px;
}

.landing-page-content .credit-monitor-content .cm-content-text li {
    list-style-type: disc;
}

.landing-page-content .reports-content .r-content-slider img,
.landing-page-content .credit-monitor-content .cm-content-slider img {
    width: 100%;
    margin: 0 auto;
}

.landing-page-content .macro-regimes-monitor-block {
    display: block;
}

.landing-page-content .macro-regimes-monitor-block {
    background-color: white;
}

.macro-regimes-monitor-content .mrm-content-text>p {
    margin-bottom: 20px;
}

.macro-regimes-monitor-content .mrm-content-text span,
.alpha-generation-content .ag-content-text span{
    font-family: 'NunitoSans-Regular', sans-serif;
    font-weight: bold;
    white-space: nowrap;
}

.macro-regimes-monitor-content .mrm-content-text>span,
.alpha-generation-content .ag-content-text>span {
    display: inline-block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 20px;
    /* text-decoration: underline; */
}

.macro-regimes-monitor-content .mrm-content-text ul {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.macro-regimes-monitor-content .mrm-content-text ul li {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-bottom: 15px;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.macro-regimes-monitor-content .mrm-content-text ul li>svg {
    width: 42px;
    min-width: 42px;
    height: 26px;
    min-height: 26px;
    margin-right: 5px;
}

.alpha-generation-content .ag-content-text>ul>li>ul{
    margin-left: 20px;
}
.alpha-generation-content .ag-content-text>ul>li>ul>li{
    list-style-type: disc;
}

.macro-regimes-monitor-content .mrm-content-text ul li>span,
.alpha-generation-content .ag-content-text ul li>span{
    margin-right: 10px;
}

.macro-regimes-monitor-content .mrm-content-text ul li .mrm-us-icon {
    width: 42px;
    min-width: 42px;
    height: 42px;
    min-height: 42px;
    margin-right: 5px;
}

.landing-page-content .solutions-block {
    padding-left: 0px;
    padding-right: 0px;
    padding-bottom: 0px;
}

.landing-page-content .solutions-content {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.landing-page-content .solutions-content .solutions-card-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    /* max-width: 1300px; */
    margin-top: 20px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
    gap: 20px;
}

.landing-page-content .solutions-content .solutions-card {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: calc(25% - 15px);
    min-width: 300px;
    height: 310px;
    padding: 10px;
    border-radius: 10px;
    -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, .1);
            box-shadow: 0 0 10px rgba(0, 0, 0, .1);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    cursor: pointer;
    background: rgba(255,255,255,.101);
}

.landing-page-content .solutions-content .solutions-card:hover {
    box-shadow: 0 0 28px rgba(255,255,255,.35), 0 0 10px rgba(255,255,255,.32);
}

.landing-page-content .solutions-content .solutions-card .solutions-card-title {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 22px;
    color: white;
}

.landing-page-content .solutions-content .solutions-card .solutions-card-title-mobile {
    display: none;
}

.landing-page-content .solutions-content .solutions-card .solutions-card-text {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 17px;
    color: white;
}

.landing-page-content .solutions-content .small-text .solutions-card .solutions-card-text{
    font-size: 15px;
}

.landing-page-content .solutions-content .solutions-card .solutions-card-icon {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.landing-page-content .solutions-content .solutions-card .solutions-card-icon svg {
    width: 210px;
    height: 95px;
    display: block;
    margin-right: 5px;
}

.landing-page-content .solutions-content .solutions-card .solutions-card-icon svg.solutions-card-icon-aim {
    width: 100px;
}

.landing-page-content .solutions-content .solutions-card .solutions-card-icon svg use {
    fill: white;
}

.landing-page-content .solutions-content .solutions-card .solutions-card-text {
    text-align: left;
    height: 190px;
}

.landing-page-content .solutions-content .how-it-works,
.landing-page-content .have-questions-block .have-questions {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 100%;
    margin-top: 50px;
    padding: 10px 7.8vw;
    border: solid 1px #cbd4dd;
    /* background-color: #eaf3fa; */
    border-left: none;
    border-right: none;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.landing-page-content .solutions-content .how-it-works .how-it-works-text,
.landing-page-content .have-questions-block .have-questions .have-questions-text {
    font-family: 'NunitoSans-Regular', sans-serif;
    margin-right: 5%;
    color: white;
}

.landing-page-content .solutions-content .how-it-works .how-it-works-text span,
.landing-page-content .have-questions-block .have-questions .have-questions-text span {
    font-size: 24px;
}

.landing-page-content .solutions-content .how-it-works .how-it-works-text p {
    font-size: 20px;
}

.landing-page-content .solutions-content .how-it-works a,
.landing-page-content .have-questions-block .have-questions a {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 24px;
    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 10px 55px;
    white-space: nowrap;
    color: #0D3866;
    border: solid 1px #d6dadd;
    background-color: #fff;
}

.landing-page-content .solutions-content .how-it-works a {
    border-radius: 5px;
}

.landing-page-content .insights-videos-content {
    width: 100%;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.landing-page-content .insights-videos-content .iv-content-slider {
    width: 100%;
    margin: 0 auto;
}

.landing-page-content .insights-videos-content .video-container .video-item-title{
    text-align: center;
    color: #fff;
    padding-bottom: 5px;
}
.landing-page-content .insights-videos-content .video-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}

.landing-page-content .insights-videos-content .iv-content-slider .image-item-block {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    max-width: 650px;
    -webkit-transform: scale(0.7);
        -ms-transform: scale(0.7);
            transform: scale(0.7);
    -webkit-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
    position: relative;
    z-index: 1;
    opacity: 0.5;
}

.landing-page-content .insights-videos-content .iv-content-slider .image-item-block.slick-active.slick-center {
    -webkit-transform: scale(1);
        -ms-transform: scale(1);
            transform: scale(1);
    opacity: 1;
    z-index: 2;
}

.landing-page-content .insights-videos-content .iv-content-slider .slick-list .slick-track {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.video-container {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.landing-page-content .start-trial-block,
.landing-page-content .partnership-block,
.landing-page-content .blog-block {
    height: auto;
    background-color: #eaf3fa;
    padding-bottom: 10px;
    padding-top: 10px;
    border: solid 1px #cbd4dd;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.landing-page-content .reviews-conteiner {
    padding-top: 25px;
    padding-bottom: 25px;
    border: none;
    font-family: NunitoSans-Regular,sans-serif;
}
.landing-page-content .reviews-conteiner .title-block {
    padding-top: 0;
    margin-bottom: 20px;
}
.landing-page-content .reviews-conteiner .title-block span{
    color: #fff;
    text-transform: uppercase;
}
.landing-page-content .reviews-conteiner .reviews-block {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    gap: 20px;
    max-width: 1300px;
}
.landing-page-content .reviews-conteiner .review {
    background-color: #fff;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.landing-page-content .reviews-conteiner .review-message {
    width: 100%;
    text-align: center;
    font-style: italic;
    padding-bottom: 10px;
    border-bottom: 1px solid #0D3866;
    margin-bottom: 10px;
}
.landing-page-content .reviews-conteiner .review-message .fa {
    font-size: 8px;
}
.landing-page-content .reviews-conteiner .reviewer{
    font-weight: 800;
    text-align: center;
}

.landing-page-content .start-trial-block {
    background-color: #fff;
    padding-top: 25px;
    padding-bottom: 25px;
    border: none;
}
.landing-page-content .start-trial-block .title-block{
    padding-top: 0;
    margin-bottom: 25px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.landing-page-content .start-trial-block span,
.landing-page-content .start-trial-block p{
    padding: 5px;
    color: #0D3866;
}
.landing-page-content .start-trial-block p{
    margin-bottom: 15px;
}
.landing-page-content .partnership-block {
    background-color: white;
}

.landing-page-content .blog-block {
    background-color: none;
    background: none;
    border-left: none;
    border-right: none;
}
.lp-start-free-trial-btn{
    background: #0D3866;
    padding: 10px;
    margin: 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
    transition: all .5s;
    font-size: 22px;
}
.lp-start-free-trial-btn:hover{
    border: 1px solid #0D3866;
    color: #0D3866;
    background: #fff;
    transition: all .5s;
}
.landing-page-content .partnership-block .title-block, 
.landing-page-content .blog-block .title-block {
    padding-top: 0px;
}

.landing-page-content .partnership-block .partnership-content, 
.landing-page-content .blog-block .blog-content {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.landing-page-content .partnership-block .partnership-content .partnership-content-center,
.landing-page-content .blog-block .blog-content .blog-content-center {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.landing-page-content .partnership-block .partnership-content {
    width: 100%;
}

.landing-page-content .partnership-block .partnership-content .partnership-content-center{
    margin: 15px auto;
    text-align: center;
    width: 100%;
}

.landing-page-content .partnership-block .partnership-content .partnership-content-center div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.landing-page-content .blog-block .blog-content .blog-content-center>div {
    margin-right: 20px;
    color: white;
}

.landing-page-content .partnership-block .partnership-content .partnership-content-center>div img{
    width: 150px;
    height: auto;
    /* margin-right: 60px; */
    /* margin-left: 60px; */
}

.landing-page-content .blog-block .blog-content .blog-content-center>div img {
    width: 250px;
    height: 130px;
}

.landing-page-content .blog-block .blog-content .blog-content-center span {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-weight: bold;
    font-size: 20px;
}

.landing-page-content .blog-block .blog-content .blog-content-center p {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 20px;
    margin-top: 5px;
    margin-bottom: 34px;
}

.landing-page-content .blog-block .blog-content .blog-content-center a {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 18px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    text-decoration: underline;
    color: white;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.landing-page-content .blog-block .blog-content .blog-content-center a i {
    width: 30px;
    height: 12px;
    margin-left: 5px;
    background: url(../../../landing/images/icons/arrow-right-mobile.svg) no-repeat 0 3px;
}

.landing-page-content .blog-block .blog-content .blog-content-right {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.landing-page-content .reports-block .subscribe-for-alerts-btn{
    margin: -2px 2px 0;
    padding: 5px 7px!important;
    text-decoration: none;
}
.landing-page-content .reports-block .subscribe-for-alerts-btn,
.landing-page-content .blog-block .blog-content .blog-content-right .subscribe-for-alerts-btn {
    font-size: 18px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px 25px;
    color: #0D3866;
    border-radius: 5px;
    background: white;
    font-family: NunitoSans-Regular, sans-serif;
    display: flex;
    align-items: center;
}

.landing-page-content .reports-block .subscribe-for-alerts-btn span,
.landing-page-content .blog-block .blog-content .blog-content-right .subscribe-for-alerts-btn span {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 35px;
    height: 35px;
    cursor: pointer;
    border: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    background: url(../../../landing/images/icons/paper-plane2.svg) center no-repeat;
    background-size: 25px,25px;
    margin-left: 5px;
}

/* .landing-page-content .blog-block .blog-content .blog-content-right p {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 16px;
    margin-bottom: 14px;
    color: #1758b4;
} */

.landing-page-content .blog-block .blog-content .blog-content-right form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.landing-page-content .blog-block .blog-content .blog-content-right input {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    padding: 12px 15px;
    color: #333;
    border: none;
    outline: none;
}

.blog-content .blog-content-right .form-group-btn {
    cursor: pointer;
}

.blog-content .blog-content-right .form-group-btn:hover {
    opacity: 0.5;
}

.blog-content .blog-content-right .form-group-btn input {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 50px;
    height: 39px;
    cursor: pointer;
    border: none;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    background: url(../../../landing/images/icons/paper-plane.svg) no-repeat;
    background-size: 20px, 20px;
    background-position: center;
}

.blog-content .blog-content-right .form-group-btn i {
    display: block;
    width: 100%;
    height: 100%;
    background-color: #0470bb;
    position: absolute;
    z-index: -1;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.landing-page-content .blog-block .blog-content .blog-content-right button:hover {
    opacity: .5;
}

.landing-page-content .about-us-block {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
}

.landing-page-content .blog-block .blog-content .blog-content-right .submit-result-text {
    display: none;
    text-align: center;
    font-size: 14px;
    font-weight: bold;
    width: 250px;
    margin: 0 auto;
    white-space: nowrap;
    padding: 12px 0;
}

.form-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.landing-page-content .blog-block .blog-content .blog-content-right .form-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    position: relative;
}

.landing-page-content .blog-block .blog-content .blog-content-right .form-group label {
    position: absolute;
    top: 45px;
    width: 100%;
    color: red;
    font-size: 14px;
}

.landing-page-content .blog-block .blog-content .blog-content-right .form-group #email-message {
    color: black;
}

.landing-page-content .blog-block .title-block span {
    color: white;
}

.landing-page-content .about-us-block .title-block {
    padding-right: 7.8vw;
    padding-left: 7.8vw;
    margin-bottom: -40px;
}

.landing-page-content .about-us-block .title-block span {
    color: white;
}

.landing-page-content .about-us-block .title-block ul li {
    list-style-type: disc;
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 22px;
}

.landing-page-content .alpha-generation-block .title-block,
.landing-page-content .credit-monitor-block .title-block,
.landing-page-content .predictive-risk-management-block .title-block,
.landing-page-content .macro-regimes-monitor-block .title-block {
    padding: 0px;
}

.landing-page-content .about-us-block .about-us-content {
    margin-bottom: 110px;
    margin-top: -35px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap;
}


/*====== PERSONS CARD TAB ======*/

.persons-block .col{ 
    display: flex;
    justify-content: center;
    width: 16%;
}
.persons-block {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.person-card-block {
    width: 90%;
    /* height: 230px; */
    margin-right: 0.7vw;
    margin-left: 0.7vw;
    margin-top: 100px;
}
.person-card-container{
    background: white;
    border-radius: 10px;
    /* box-shadow: 5px 5px 20px  rgba(219, 223, 226, 0.5);*/
    /* box-shadow: 0 0 15px 2px #d0d0d0; */
    /* box-shadow: 5px -5px 10px 5px rgba(109, 154, 206, 0.2); */
    box-shadow: 6px 2px 20px 2px rgba(109, 154, 206, 0.2);
    padding: 10px;
    height: 100%;
    display: grid;
    grid-template-rows: 5fr 1fr 5fr;
    grid-template-rows: auto 50px 1fr;
    align-items: center;
    grid-template-areas:
        "p"
        "t"
        "i";
}
.person-card-container:hover{
    box-shadow: 6px 2px 20px 2px rgba(109, 154, 206, 0.85);
}
.personal-card-title{
    grid-area: t;
}
.personal-card-info{
    display: block;
    padding: 5px 0 0 20px;
    align-items: center;
    grid-area: i;
}
.personal-card-info li{
    list-style-type: disc;
    color: #333;
    font-size: 12px;
    padding: 2px 0px;
}
.personal-card-picture{
    width: 95%;
    margin: 0 auto;
    grid-area: p;
}


.personal-card-picture img{
    border-radius: 10px;
}

.person-card-block a{
    width: fit-content;
    margin: 0 auto;
}
.person-card-block a,
.person-card-block p {
    display: block;
    text-align: center;
    /* color: white; */
    color: #333;
}

.person-card-block a {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-weight: bold;
    font-size: 18px;
    margin-top: 0.5em;
}

.person-card-block p {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 16px;
}

.person-card-block span {
    font-weight: normal;
}

.person-card {
    width: 213px;
    height: 230px;
    position: relative;
    -webkit-perspective: 1000px;
            perspective: 1000px;
    cursor: pointer;
    margin-bottom: 20px;
}


.person-card-front picture,
.person-card-front img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.person-card-front,
.person-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-transition: 1s;
    -o-transition: 1s;
    transition: 1s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 10px;
    -webkit-box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.72);
            box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.72);
}

.person-card-back ul li {
    list-style-type: disc;
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 14px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-left: 20px;
}

.person-card-front {
    background-color: #fff;
}

/* .person-card-back {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.person-card:hover .person-card-front {
    -webkit-transform: rotateY(180deg);
            transform: rotateY(180deg);
}

.person-card:hover .person-card-back {
    -webkit-transform: rotateY(360deg);
            transform: rotateY(360deg);
} */

.container{
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
      -webkit-perspective: 1000px;
              perspective: 1000px;
    /* width: 213px; */
  }
  
/*   .front img,
  .front,
  .back{
    background-size: cover;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.25);
    border-radius: 10px;
      background-position: center;
      -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      text-align: left;
      min-height: 230px;
      max-height: 230px;
      height: auto;
      border-radius: 10px;
      color: #0D3866;
      font-size: 0.7rem;
  } */
  
/*   .back{
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0) 20%);
    background-color: white;
  } */
  
/*   .front:after{
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      width: 100%;
      height: 100%;
      content: '';
      display: block;
      opacity: .0;
      background-color: #000;
      -webkit-backface-visibility: hidden;
              backface-visibility: hidden;
      border-radius: 10px;
  }
  .container:hover .front,
  .container:hover .back{
      -webkit-transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      -o-transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
      transition: transform .7s cubic-bezier(0.4, 0.2, 0.2, 1), -webkit-transform .7s cubic-bezier(0.4, 0.2, 0.2, 1);
  }
  
  .back{
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
  }
  
  .inner{
      -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
              transform: translateY(-50%) translateZ(60px) scale(0.94);
      top: 50%;
      position: absolute;
      left: 0;
      width: 100%;
      padding: 1.2rem;
      -webkit-box-sizing: border-box;
              box-sizing: border-box;
      outline: 1px solid transparent;
      -webkit-perspective: inherit;
              perspective: inherit;
      z-index: 2;
  }

  .inner ul li {
      list-style-type: disc !important;
  } */
  
/*   .container .back{
      -webkit-transform: rotateY(180deg);
              transform: rotateY(180deg);
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
  }
  
  .container .front{
      -webkit-transform: rotateY(0deg);
              transform: rotateY(0deg);
      -webkit-transform-style: preserve-3d;
              transform-style: preserve-3d;
  }
  
  .container:hover .back{
    -webkit-transform: rotateY(0deg);
            transform: rotateY(0deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  }
  
  .container:hover .front{
    -webkit-transform: rotateY(-180deg);
            transform: rotateY(-180deg);
    -webkit-transform-style: preserve-3d;
            transform-style: preserve-3d;
  } */
  
/*   .front .inner p{
    font-size: 2rem;
    margin-bottom: 2rem;
    position: relative;
  }
  
  .front .inner p:after{
    content: '';
    width: 4rem;
    height: 2px;
    position: absolute;
    background: #C6D4DF;
    display: block;
    left: 0;
    right: 0;
    margin: 0 auto;
    bottom: -.75rem;
  }
  
  .front .inner span{
    color: rgba(255,255,255,0.7);
    font-family: 'Montserrat';
    font-weight: 300;
  } */

/*====== END PERSONS CARD TAB ======*/

.landing-page-content .have-questions-block .have-questions {
    margin-top: 0;
}

.landing-page-content .have-questions-block .have-questions .have-questions-text {
    margin-right: 20%;
}



/*=====container-enlarging-img=====*/

.container-enlarging-img {
    width: 100%;
    height: 100vh;
    background-color: rgba(74, 72, 69, 0.5);
    position: fixed;
    top: 0px;
    z-index: 100;
}

.container-enlarging-img .block-enlarging-img {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
        -ms-flex-pack: center;
            justify-content: center;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

.container-enlarging-img .block-enlarging-img video {
    width: 95%;
}
.container-enlarging-img .block-enlarging-img img {
    max-width: 100%;
    width: auto;
    max-height: 100%;
    height: auto;
    cursor: -webkit-zoom-out;
    cursor: zoom-out;
}

img.image-enlargement-item {
    width: 100%;
    height: 100%;
    cursor: -webkit-zoom-in;
    cursor: zoom-in;
}


/*===== end container-enlarging-img=====*/


/* ===========login-form-parent================ */

.container-login-form {
    width: 100%;
    height: 100vh;
    background-color: rgba(74, 72, 69, 0.5);
    position: fixed;
    top: 0px;
    z-index: 100;
}

.login-form-parent {
    max-width: 475px;
    border: solid 1px #e7e5e6;
    -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, .1);
            box-shadow: 0 0 50px rgba(0, 0, 0, .1);
    background-color: #ffffff;
    padding: 25px 25px;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

.close-login {
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 2px;
    font-size: 20px;
    opacity: 0.5;
    cursor: pointer;
}

.close-login:hover {
    opacity: 0.8;
}

.login-form {
    padding: 25px 20px 10px;
}

.login .error {
    font-size: 11px;
    font-weight: normal;
    line-height: 1;
    /* white-space: nowrap; */
    color: red;
    margin-bottom: 2px;
}

.login {
    margin-top: 5px;
}

.login .panel-heading {
    display: none;
}

.login .form-group {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    width: min-content;
    min-width: 220px;
}

.login .form-group-btn {
    width: 120px;
    margin: 0 auto;
    text-transform: uppercase;
}

.login .form-control,
.registration .form-control {
    height: 28px;
    padding: 5px;
}

.registration .form-group .btn {
    font-size: 13px;
    background: #356fb7;
    padding: 0 15px;
}

.registration .form-group {
    display: inline-block;
    vertical-align: top;
    margin: 0;
    position: relative;
    z-index: 2;
}

.registration .form-group label.error {
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: -25px;
    font-size: 11px;
    font-weight: normal;
}

.registration .form-control {
    width: 200px;
}

.form-control,
.panel {
    border-radius: 0;
}

.login-form-parent .form-title {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
}

.login-form-parent .form-title span {
    display: block;
    margin-bottom: 15px;
    font-size: 20px;
}

.login-form-parent .form-title a {
    font-family: 'NunitoSans-Italic', sans-serif;
    margin-left: 5px;
    /* text-decoration: underline; */
    color: #0D3866;
}

.login-form-parent .form-control {
    font-family: 'NunitoSans-Italic', sans-serif;
    font-size: 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 205px;
    margin-bottom: 5px;
    padding: 6px 5px;
    color: #0D3866;
    /* border: solid 1px #d9d9d9; */
}

.login-form-parent .form-control::-webkit-input-placeholder,
.login-form-parent .form-control::-moz-placeholder,
.login-form-parent .form-control:-ms-input-placeholder,
.login-form-parent .form-control::-ms-input-placeholder,
.login-form-parent .form-control::placeholder {
    color: #0D3866;
}

.login-form-parent .form-remember-me {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 16px;
    margin-bottom: 10px;
    margin-top: 10px;
}

.login-form-parent .reset-password-link a {
    font-family: 'NunitoSans-Regular', sans-serif;
    margin-left: 5px;
    text-decoration: underline;
    color: #0D3866;
}

.login-form-parent form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.login-form-parent button {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    margin-bottom: 40px;
    padding: 7px 30px;
    cursor: pointer;
    text-align: center;
    color: #0D3866;
    border: solid 1px #d9d9d9;
    background: none;
}

.login-form-parent button:hover {
    box-shadow: 0 5px 20px rgba(15, 15, 15, .4);
}

.login-form-parent .telephone-email {
    margin: 10px 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: center;
    -ms-flex-pack: justify;
    justify-content: center;
    gap: 10px;
}

.login-form-parent .telephone-email a {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #0D3866;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.login-form-parent .telephone-email a i {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 0 5px;
}

.login-form-parent .telephone-email .footer-phone i {
    background: url(../../../landing/images/icons/phonereceiver.svg) no-repeat;
    background-size: contain;
}

.login-form-parent .telephone-email .footer-email i {
    background: url(../../../landing/images/icons/email-icon.svg) no-repeat;
    background-size: contain;
}


/* ===========END login-form-parent================ */


/* ===========request-demo-form================ */

.container-request-demo-form {
    width: 100%;
    height: 100vh;
    background-color: rgba(74, 72, 69, 0.5);
    position: fixed;
    top: 0px;
    z-index: 100;
}

.request-demo-form-wrapper {
    max-width: 475px;
    border: solid 1px #e7e5e6;
    -webkit-box-shadow: 0 0 50px rgba(0, 0, 0, .1);
            box-shadow: 0 0 50px rgba(0, 0, 0, .1);
    background-color: #ffffff;
    position: relative;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    padding: 25px 85px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.close-request-demo {
    position: absolute;
    z-index: 2;
    right: 8px;
    top: 2px;
    font-size: 20px;
    opacity: 0.5;
    cursor: pointer;
}

.close-request-demo:hover {
    opacity: 0.8;
}

.request-demo-form-wrapper .form-title {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 18px;
    margin-bottom: 10px;
    text-align: center;
}

.request-demo-form-wrapper .form-title span {
    display: block;
    margin-bottom: 15px;
    font-size: 20px;
}

.request-demo-form-wrapper .form-title a {
    font-family: 'NunitoSans-Italic', sans-serif;
    margin-left: 5px;
    /* text-decoration: underline; */
    color: #0D3866;
}

.request-demo-form-wrapper form {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.request-demo-form-wrapper form input#terms-check {
    width: auto;
}
.request-demo-form-wrapper form input {
    font-family: 'NunitoSans-Italic', sans-serif;
    font-size: 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 205px;
    /* margin-bottom: 5px; */
    padding: 6px 5px;
    color: #0D3866;
    /* border: solid 1px #d9d9d9; */
    border: none;
    box-shadow: none;
    -webkit-box-shadow: none;
}
form .selectize-input{
    box-shadow: none;
    -webkit-box-shadow: none;
}

.request-demo-form-wrapper form input::-webkit-input-placeholder,
.request-demo-form-wrapper form input::-moz-placeholder,
.request-demo-form-wrapper form input:-ms-input-placeholder,
.request-demo-form-wrapper form input::-ms-input-placeholder,
.request-demo-form-wrapper form input::placeholder {
    color: #0D3866;
}

.request-demo-form-wrapper .form-group .selectize-input,
.request-demo-form-wrapper .form-group .selectize-input input {
    font-size: 18px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    width: 205px;
    /* margin-bottom: 5px; */
    padding: 3px 5px;
    color: #0D3866;
    /* border: solid 1px #d9d9d9; */
    border: none;
    font-family: 'NunitoSans-Regular', sans-serif;
}

.request-demo-form-wrapper .selectize-dropdown-content {
    font-size: 14px;
    color: #0D3866;
    font-family: 'NunitoSans-Regular', sans-serif;
}

.request-demo-form-wrapper .optgroup-header {
    font-size: 14px;
    font-weight: bold;
    border-bottom: solid 1px #d9d9d9;
    margin: 0 5px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.request-demo-form-wrapper form .form-captcha {
    width: 205px;
    margin-top: 15px;
    margin-bottom: 15px;
}
.btn.btn-primary.submit{
    color: #fff;
    background: #0D3866;
    opacity: 1;
}
.btn.btn-primary.submit:hover{
    box-shadow: 0 5px 20px rgba(15, 15, 15, .4);
}
.btn.btn-primary.submit.disabled:hover{
    opacity: .4;
}
.btn.btn-primary.submit.disabled{
    color: #fff;
    background: #0D3866;
    opacity: .6;
    cursor: not-allowed;
}
.request-demo-form-wrapper form .form-captcha label,
.request-demo-form-wrapper form .form-captcha p {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 12px;
    text-align: center;
}
.form-captcha input{
    position: relative;
    top: 2px;
    left: 0px;
    margin: 0;
}
.request-demo-form-wrapper form .form-captcha a {
    font-family: 'NunitoSans-Italic', sans-serif;
    font-size: 12px;
    color: #1758b4;
}
.grecaptcha-badge {
    display: none;
    visibility: visible!important;
}
.request-demo-form-wrapper form .form-group-submit input {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 16px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    /* margin-bottom: 40px; */
    margin-bottom: 20px;
    padding: 7px 30px;
    cursor: pointer;
    text-align: center;
    color: #0D3866;
    border: solid 1px #d9d9d9;
    background: none;
    width: auto;
}

/* .request-demo-form-wrapper form .form-group-submit input:hover {
    opacity: .5;
} */

.request-demo-form-wrapper form .error {
    color: red;
}

.request-demo-form-wrapper form .message {
    margin-bottom: 10px;
}

.request-demo-form-wrapper .telephone-email {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
            justify-content: space-between;
}

.request-demo-form-wrapper .telephone-email a {
    font-family: 'NunitoSans-Regular', sans-serif;
    font-size: 12px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    color: #0D3866;
    -webkit-box-align: center;
    -webkit-align-items: center;
        -ms-flex-align: center;
            align-items: center;
}

.request-demo-form-wrapper .telephone-email a i {
    display: inline-block;
    width: 20px;
    height: 20px;
    margin-right: 5px;
    margin-left: 10px;
}

.request-demo-form-wrapper .telephone-email .footer-phone i {
    background: url(../../../landing/images/icons/phonereceiver.svg) no-repeat;
    background-size: contain;
}

.request-demo-form-wrapper .telephone-email .footer-email i {
    background: url(../../../landing/images/icons/email-icon.svg) no-repeat;
    background-size: contain;
}


/* ===========END request-demo-form================ */


/* =========container-disclaimer========== */

.container-disclaimer {
    width: 100%;
    height: 100vh;
    background-color: rgba(74, 72, 69, 0.5);
    position: fixed;
    top: 0px;
    z-index: 1000;
    font-family: 'NunitoSans-Regular', sans-serif;
    color: #121212;
    font-size: 14px;
}

#disclaimer {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 5px;
}

#disclaimer:focus {
    outline: none;
}

.container-disclaimer h4 {
    line-height: 1.42857143;
    font-size: 18px;
    font-family: inherit;
    font-weight: 500;
    color: inherit;
}

.container-disclaimer .modal-header {
    position: relative;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.container-disclaimer .modal-header button {
    position: absolute;
    right: 10px;
    cursor: pointer;
    background: 0 0;
    border: 0;
    font-size: 34px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    filter: alpha(opacity=20);
    opacity: .2;
    outline: none;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

.container-disclaimer .modal-header button:hover {
    opacity: 0.8;
}

.container-disclaimer .modal-body {
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.container-disclaimer .modal-body h4,
.container-disclaimer .modal-body p {
    margin-bottom: 20px;
}

.container-disclaimer .modal-footer {
    padding: 15px;
    border-top: 1px solid #e5e5e5;
}
/* =========end container-disclaimer========== */

/* =========container-privacy========== */

.container-privacy {
    width: 100%;
    height: 100vh;
    background-color: rgba(74, 72, 69, 0.5);
    position: fixed;
    top: 0px;
    z-index: 1000;
    font-family: 'NunitoSans-Regular', sans-serif;
    color: #121212;
    font-size: 14px;
}

#privacy {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
    background-color: #ffffff;
    border-radius: 5px;
}

#privacy:focus {
    outline: none;
}

.container-privacy h4 {
    line-height: 1.42857143;
    font-size: 18px;
    font-family: inherit;
    font-weight: 500;
    color: inherit;
}

.container-privacy .modal-header {
    position: relative;
    padding: 15px;
    border-bottom: 1px solid #e5e5e5;
}

.container-privacy .modal-header button {
    position: absolute;
    right: 10px;
    cursor: pointer;
    background: 0 0;
    border: 0;
    font-size: 34px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    filter: alpha(opacity=20);
    opacity: .2;
    outline: none;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
}

.container-privacy .modal-header button:hover {
    opacity: 0.8;
}

.container-privacy .modal-body {
    padding: 10px;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}

.container-privacy .modal-body h4,
.container-privacy .modal-body p {
    margin-bottom: 5px;
}

.container-privacy .modal-body ul {
    margin-left: 20px;
    margin-bottom: 5px;
}

.container-privacy .modal-body li {
    list-style-type: disc;
}

.container-privacy .modal-footer {
    padding: 15px;
    border-top: 1px solid #e5e5e5;
}
/* =========end container-privacy========== */

